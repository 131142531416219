<template>
  <div id="form-noleggio">
    <div class="logo-container">
      <img src="/img/preventivo/noltech_povered_by_elmec.svg" width="380"/>
    </div>

    <div class="row col-xs-12">
      <div class="form-group col-md-6 col-xs-12">
        <label for="firstname">Nome</label><label class="star">*</label>
        <!--      <input v-model="form.firstname" type="text" id="firstname" name="firstname" placeholder="Your name..">-->
        <my-input
            id="firstname"
            v-bind:class="{'error': errors.has('firstname')}"
            :status="errors.has('firstname') ? 'error' : ''"
            v-validate="{ required: true }"
            :name="'firstname'"
            v-model="form.firstname"
            :label="''"
        ></my-input>
        <p class="error" v-if="errors.has('firstname')">Il campo nome è obbligatorio</p>
      </div>

      <div class="form-group col-md-6 col-xs-12">
        <label for="lastname">Cognome</label><label class="star">*</label>
        <!--      <input v-model="form.lastname" type="text" id="lastname" name="lastname" placeholder="Your surname..">-->
        <my-input
            id="lastname"
            v-bind:class="{'error': errors.has('lastname')}"
            :status="errors.has('lastname') ? 'error' : ''"
            v-validate="{ required: true }"
            :name="'lastname'"
            v-model="form.lastname"
            :label="''"
        ></my-input>
        <p class="error" v-if="errors.has('lastname')">Il campo cognome è obbligatorio</p>
      </div>
    </div>


    <div class="form-group col-xs-12">
      <label for="company">Ragione sociale</label><label class="star">*</label>
<!--      <input v-model="form.company" type="text" id="company" name="company" placeholder="Your ragsoc..">-->
      <my-input
          id="company"
          v-bind:class="{'error': errors.has('company')}"
          :status="errors.has('company') ? 'error' : ''"
          v-validate="{ required: true }"
          :name="'company'"
          v-model="form.company"
          :label="''"
      ></my-input>
      <p class="error" v-if="errors.has('company')">Il campo rag. sociale è obbligatorio</p>
    </div>

    <div class="row col-xs-12">
      <div class="form-group col-md-6 col-xs-12">
        <label for="email">Email aziendale</label><label class="star">*</label>
        <my-input
            id="email"
            v-bind:class="{'error': errors.has('email')}"
            :status="errors.has('email') ? 'error' : ''"
            v-validate="{ required: true, regex:/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([,+;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/ }"
            :name="'email'"
            v-model="form.email"
            :label="''"
        ></my-input>
        <p class="error" v-if="errors.has('email')">Il campo email è obbligatorio</p>
      </div>

      <div class="form-group col-md-6 col-xs-12">
        <label for="phone">Numero di telefono</label><label class="star">*</label>
  <!--      <input v-model="form.phone" type="text" id="phone" name="phone" placeholder="Your cell..">-->
        <my-input
            id="phone"
            v-bind:class="{'error': errors.has('phone')}"
            :status="errors.has('phone') ? 'error' : ''"
            v-validate="'required|min:7|max:20'"
            :name="'phone'"
            v-model="form.phone"
            :label="''"
        ></my-input>
        <p class="error" v-if="errors.has('phone')">Il campo telefono è obbligatorio</p>
      </div>
    </div>

<!--    <div class="form-group col-xs-12 clearfix">-->
<!--      <label for="quante_postazioni_di_lavoro_gestisci_">Quante postazioni di lavoro vorresti noleggiare?</label><label class="star">*</label>-->
<!--      <multiselect-->
<!--          required-->
<!--          v-validate="'required'"-->
<!--          :status="errors.has('quante_postazioni_di_lavoro_gestisci_') ? 'error' : ''"-->
<!--          id="quante_postazioni_di_lavoro_gestisci_"-->
<!--          class="type"-->
<!--          :label="''"-->
<!--          :name="'quante_postazioni_di_lavoro_gestisci_'"-->
<!--          v-model="form.quante_postazioni_di_lavoro_gestisci_"-->
<!--          :options="postazioniOptions"-->
<!--          :label-name="'label'"-->
<!--          :value-name="'label'"-->
<!--          :open-direction="'bottom'"-->
<!--          :placeholder="'Scegli il numero ideale per il tuo business'"-->
<!--          :showNoResult="false"-->
<!--      ></multiselect>-->
<!--      <p class="error" v-if="errors.has('quante_postazioni_di_lavoro_gestisci_')">Il campo postazioni di lavoro è obbligatorio</p>-->
<!--    </div>-->

    <div class="form-group col-xs-12">
      <label for="note_opzionali_noltech">Note</label>
      <label class="note-description">Scrivi di seguito le tue richieste aggiuntive: ne terremo conto nella preparazione della tua proposta dedicata.</label>
      <textarea class="textarea" v-model="form.note_opzionali_noltech" id="note_opzionali_noltech" name="note_opzionali_noltech" placeholder="Scrivi un elenco.." style="height:200px"></textarea>
    </div>

    <div class="form-group col-xs-12 checkbox-container">
      <check-box
          :label="''"
          v-model="consent.communications[0].value"
      >
        <template slot="custom-label">
          <div class="custom-checkbox-label-template">
            <span class="label" v-html="consent.communications[0].text"/>
          </div>
        </template>
      </check-box>
    </div>

    <div class="form-group col-xs-12 checkbox-container">
      <check-box
          id="checkbox-consent"
          :status="errors.has('checkbox-consent') ? 'error' : ''"
          :label="''"
          name="checkbox-consent"
          v-model="consent.checked"
          v-on:change="checkBoxChange()"
          v-validate="'required'"
      >
        <template slot="custom-label">
          <div class="custom-checkbox-label-template">
            <span class="label" v-html="consentLabel"/>
          </div>
        </template>
      </check-box>
      <p class="error" v-if="errors.has('checkbox-consent')">Il campo privacy è obbligatorio</p>
    </div>


    <div class="button-container col-xs-12">
      <button @click="sendForm()"
              :disabled="submitBtnDisable"
              class="btn btn-primary">RICHIEDI SUBITO
      </button>

      <p class="error" v-if="$validator.errors && $validator.errors.items.length > 0">Attenzione! Compila tutti i campi obbligatori.</p>
    </div>

  </div>
</template>

<script>
  import MyInput from './global/MyInput'
  // import Multiselect from './global/Multiselect'
  import swal from 'sweetalert2'
  import CheckBox from './global/CheckBox'

  const axios = require('axios')

  export default {
    components: {CheckBox, MyInput},
    name: 'form-noleggio',
    props: {
    },
    data () {
      return {
        submitBtnDisable: false,
        formId: '9ae23f34-3d56-4aa4-912c-d75f08da267b',
        portalId: '5099766',
        consentCheck: false,
        form: {
          firstname: '',
          lastname: '',
          company: '',
          email: '',
          phone: '',
          // quante_postazioni_di_lavoro_gestisci_: '',
          note_opzionali_noltech: '',
          configurazione_noleggio: this.getCartTemplate(this.$store.getters.cart) // carrello
        },
        consentLabel: 'Dichiaro di aver preso visione dell\'informativa <a target="_blank" href="https://www.elmec.com/privacy/Standard-informativa-richiesta-contatti-GDPR-online.pdf">privacy</a>.<span class="star">*</span>',
        consent: {
          label: 'Dichiaro di aver preso visione dell\'informativa privacy.',
          value: true,
          communications: [
            {
              'value': false,
              'subscriptionTypeId': 999,
              'text': 'Esprimo il consenso al trattamento dei miei dati per finalità di marketing profilato: analisi delle mie preferenze, abitudini, comportamenti, desunti dai dati da me conferiti direttamente in combinazione con i dati relativi alla mia navigazione sul nostro sito web raccolti tramite cookie, al fine di permettere ad Elmec Informatica l’erogazione di comunicazioni commerciali personalizzate e l’effettuazione di azioni promozionali mirate rispetto ai miei interessi.'
            }
          ]
        },
        postazioniOptions: [
          {
            label: '10-25',
            value: '10-25'
          },
          {
            label: '25-50',
            value: '25-50'
          },
          {
            label: '50-100',
            value: '50-100'
          },
          {
            label: '100-200',
            value: '100-200'
          },
          {
            label: '200-500',
            value: '200-500'
          },
          {
            label: '500+',
            value: '500+'
          }
        ]
      }
    },
    mounted: function () {
    },
    methods: {
      checkBoxChange: function () {
        if (!this.consent.checked) {
          delete this.consent.checked
        }
      },
      getCartTemplate: function (cart) {
        let configuration = ''
        let attributeMapping = {
          device: 'Tipo dispositivi',
          band: 'Fascia dispositivo',
          quantity: 'Quantità',
          contractLife: 'Durata contratto',
          vendor: 'Vendor',
          accessories: 'Accessori',
          activableServices: 'Servizi attivabili',
        }
        cart.forEach(item => {
          configuration += configuration ? '\n' : ''
          for (let attr in item) {
            if (item[attr] && item[attr].length > 0) {
              configuration += ('\n' + attributeMapping[attr] + ': ' +
                (
                    attr === 'vendor' ? item[attr].toString().toUpperCase() :
                    item[attr].toString()
                )
              )
            }
          }

        })
        return configuration.toString()
      },
      sendForm: function () {
        this.submitBtnDisable = true
        this.$validator.validateAll().then(result => {
          // console.log('validate: ', result)
          if (result) {
            this.sendHubspotForm()
          } else {
            this.submitBtnDisable = false
            // console.log('errors: ', this.$validator.errors)
          }
        })
      },
      sendHubspotForm: function () {
        let url = 'https://api.hsforms.com/submissions/v3/integration/submit/'+this.portalId+'/'+this.formId

        let fields = []

        for (let attr in this.form) {
          fields.push(
              {
                name: attr,
                value: this.form[attr]
              }
          )
        }

        let body = {
          'fields': fields,
          'legalConsentOptions': {
            'consent': { // Include this object when GDPR options are enabled
              'consentToProcess': this.consent.value,
              'text': this.consent.label,
              'communications': this.consent.communications
            }
          }
        }

        axios.post(url, body).then(response => {
              if (response.status === 200) {
                this.$store.dispatch('resetCart')
                this.submitBtnDisable = false

                this.$router.push({
                  name: 'ThankYou'
                })
              } else {
                this.submitBtnDisable = false
                swal({
                  title: '',
                  text: 'Si è verificato un problema con la richiesta. Riprovare più tardi',
                  type: 'error'
                })
              }
        }).catch(() => {
          this.submitBtnDisable = false
          swal({
            title: '',
            text: 'Si è verificato un problema con la richiesta. Riprovare più tardi',
            type: 'error'
          })
        })
      }
    }
  }
</script>

<style lang="less">
  #form-noleggio {
    padding: 15px;
    color: #33475b;

    .logo-container {
      text-align: center;
    }

    .btn {
      padding: 12px 24px;
      margin-bottom: 3px;
    }

    .star {
      margin-left: 2px;
      color: darkred;
      font-size: 13px;
      font-weight: bold;
    }

    .error {
      margin-bottom: 0;
      color: darkred;
      font-size: 10px;
      font-style: italic;
      text-align: left;
    }

    .note-description {
      font-size: 10px;
      color: gray;
    }

    .button-container {
      text-align: center;

      &.btn-primary {
        letter-spacing: 0;
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
      }
    }

    .my-input-container {
      .my-input {
        width: 100%;
      }
    }

    .multiselect {
      .multiselect__tags {
        height: 40px;
        .multiselect__placeholder {
          padding: 6px 0 0 0;
        }
        .multiselect__input {
          padding: 6px 0 0 0;
        }
        .multiselect__single {
          padding: 8px 0 0 0;
        }
      }
      .multiselect__select {
        top: 0;
      }
    }

    .textarea {
      width: 100%;
      background-color: #F4F8FB;
      outline: none;
      padding: 4px 25px 5px 10px;
      border: 1px solid #C4CBCC;
      border-radius: 3px;
      letter-spacing: 1px;
      color: #33475b;
    }


    .checkbox-container {
      .custom-checkbox-label-template {
        font-size: 12px;
        margin-left: 10px;
      }
    }

  }

</style>
