<template>
  <!--body content wrap start-->
  <div class="main">
    <!--hero section start-->
    <section class="hero-section ptb-100  background-img full-screen" style="background: url('/img/hero-bg-shape-3.svg')no-repeat center center / cover">

      <div class="container">
        <div class="row pt-5 pt-sm-5 pt-md-5 pt-lg-0">
          <div class="col-md-7 col-lg-7">
            <div style="padding: 16px" class="card login-signup-card shadow-lg mb-0">
<!--                <hubspot-form :form-id="'9ae23f34-3d56-4aa4-912c-d75f08da267b'"/>-->
              <form-noleggio/>
            </div>
          </div>
          <div class="col-md-5 col-lg-5">
            <carrello :custom-height="myHeight - 150" :action-btns="false"/>
          </div>
        </div>
      </div>
    </section>
    <!--hero section end-->

    <!--shape image start-->
    <div style="transform: scaleY(-1);" class="shape-img subscribe-wrap row">
      <img style="margin-left: 15px;" src="/img/footer-top-shape.png" alt="footer shape" class="img-fluid">
    </div>
    <!--shape image end-->

  </div>
  <!--body content wrap end-->
</template>

<script>
  import {commonMixins} from '../mixins/common-mixins'
  import Carrello from './Carrello'
  import FormNoleggio from './FormNoleggio'

  export default {
    name: 'PreventivoNoleggio',
    components: {FormNoleggio, Carrello},
    mixins: [commonMixins],
    created() {
      if (this.$store.getters.cart.length <= 0) {
        this.$router.push({
          name: 'Configurator'
        })
      }
    },
    data () {
      return {
        myHeight: window.innerHeight
      }
    },
    mounted: function () {
      this.goTopPage()
    }
  }
</script>

<style scoped>

</style>
